






import { Component, Vue, Emit } from 'vue-property-decorator';
import Error from '../../../../components/contents/Error.vue';

@Component({
  components: {
    Error,
  },
})
export default class EditAccountMail extends Vue {
  private status: number = 200;

  private stepNavi: StepNaviData[] = [
    {
      title: '情報変更',
    },
    {
      title: '本人確認メール送信完了',
    },
    {
      title: 'ご本人確認',
    },
    {
      title: '変更完了',
    },
  ];

  @Emit()
  private setStatus(value: number): void {
    this.status = value;
  }

}
